// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Groups_root__3Romb {\n  background-color: white;\n  padding: 18px 48px;\n}\n\n.Groups_secondary__1bmqC {\n  background-color: #F2F2F2;\n}\n\n.Groups_title__1nTUr {\n  font-size: 24px;\n  font-weight: 700;\n}\n\n.Groups_flexContainer__1WtEH {\n  display: flex;\n  flex-wrap: wrap;\n  margin: 0 20px;\n}\n\n.Groups_singleGroup__1KRwr {\n  width: 120px;\n  height: 120px;\n  border-radius: 50%;\n  color: white !important;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  font-size: 12px;\n  margin: 12px;\n}\n\n.Groups_membersCount__3_6ZU {\n  font-size: 24px;\n}\n\n.Groups_chatRoot__2lA6g {\n  background-color: white;\n}", "",{"version":3,"sources":["webpack://src/component/Groups.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,kBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,cAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,uBAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;EAEA,YAAA;AADF;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,uBAAA;AADF","sourcesContent":[".root {\r\n  background-color: white;\r\n  padding: 18px 48px;\r\n}\r\n\r\n.secondary {\r\n  background-color: #F2F2F2;\r\n}\r\n\r\n.title {\r\n  font-size: 24px;\r\n  font-weight: 700;\r\n}\r\n\r\n.flexContainer {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  margin: 0 20px;\r\n}\r\n\r\n.singleGroup {\r\n  width: 120px;\r\n  height: 120px;\r\n  border-radius: 50%;\r\n  color: white !important;\r\n\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex-direction: column;\r\n  font-size: 12px;\r\n\r\n  margin: 12px;\r\n}\r\n\r\n.membersCount {\r\n  font-size: 24px;\r\n}\r\n\r\n.chatRoot{\r\n  background-color: white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Groups_root__3Romb",
	"secondary": "Groups_secondary__1bmqC",
	"title": "Groups_title__1nTUr",
	"flexContainer": "Groups_flexContainer__1WtEH",
	"singleGroup": "Groups_singleGroup__1KRwr",
	"membersCount": "Groups_membersCount__3_6ZU",
	"chatRoot": "Groups_chatRoot__2lA6g"
};
export default ___CSS_LOADER_EXPORT___;
