import React, { useEffect } from 'react'
import { getFirestore, serverTimestamp } from 'firebase/firestore'
import { FirestoreProvider, AuthProvider, useFirebaseApp } from 'reactfire'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

import CurrentEvent from './component/CurrentEvent'
import UpcomingEvents from './component/UpcomingEvents'
import AuthGate, { SignIn, SignOut } from './component/AuthGate'
import MainLayout from './component/MainLayout'
import Places from './component/Places'
import Groups from './component/Groups'
import YourGroups from './component/YourGroups'

import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'

function App(): JSX.Element {

  const firebaseApp = useFirebaseApp()
  const firestore = getFirestore(firebaseApp)
  const auth = getAuth(firebaseApp)

  useEffect(()=>{
    onAuthStateChanged(auth, async user => {
      if ( user ){
        const userDataUdate = {
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          uid: user.uid,
          lastUpdate: serverTimestamp(),
        }

        const uRef = doc(firestore, 'users', user.uid )
        const docScap = await getDoc( uRef )

        if ( docScap.exists() ){
          updateDoc( uRef, userDataUdate )
        } else {
          setDoc( uRef, userDataUdate )
        }
      }
    })
  }, [auth])

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestore}>
        <MainLayout topContent={<SignIn/>} >
          <SignOut/>
          <AuthGate>
            <CurrentEvent/>
            <UpcomingEvents/>
            {/*<Places />*/}
            <YourGroups />
            <Groups />
          </AuthGate>
        </MainLayout>
      </FirestoreProvider>
    </AuthProvider>
  )
}

export default App

