import { serverTimestamp, addDoc, collection, DocumentReference} from 'firebase/firestore'
import React, { useState } from 'react'
import { useFirestoreDocData, useFirestore } from 'reactfire'
import useUser from './useUser'

import style from './Place.module.scss'
interface PlaceProps {
  placeRef: DocumentReference;
  onClick?: any;
  name?: boolean;
  address?: boolean;
  distance?: boolean;
  foodType?: boolean;
}

const CreatePlace = () => {
  const db = useFirestore()
  const [_, userRef] = useUser()

  const [ open, setOpen ] = useState(false)
  const [ name, setName ] = useState('')
  const [ placeId, setPlaceId ] = useState('')
  const [ address, setAddress ] = useState('')
  const [ distance, setDistance ] = useState('0')
  const [ foodTypes, setFoodTypes ] = useState('')
  const [ image, setImage ] = useState('')

  const onClickAddPlace = () => {
    setName('')
    setPlaceId('')
    setAddress('')
    setDistance('')
    setImage('')
    setOpen( true )
  }
  const onClose = () => {
    setOpen( false )
  }

  const onSubmit = ()=>{
    addDoc(collection(db, 'places'), {
      name, placeId, address, distance, image,
      createdBy: userRef,
      createdAt: serverTimestamp()
    })
    onClose()
  }

  const submitDisabled = ()=>[name, placeId, address, distance, image].includes('')

  return (
    <>
      <button disabled={open} onClick={onClickAddPlace}>new place</button>
      {
        open && (
          <div>
            <div style={{ border: '1px solid black', margin: 10 }}>
              <p>add new place:</p>
              <input placeholder="placeId" value={ placeId } onChange={(event)=>setPlaceId(event.target.value)}/>
              <input placeholder="address" value={ address } onChange={(event)=>setAddress(event.target.value)}/>
              <input placeholder="name" value={ name } onChange={(event)=>setName(event.target.value)}/>
              <input placeholder="distance" type='number' value={ distance } onChange={(event)=>setDistance(event.target.value)}/>
              <input placeholder="image url" value={ image } onChange={(event)=>setImage(event.target.value)}/>
              <input placeholder="flags (optional)" value={ foodTypes } onChange={(event)=>setFoodTypes(event.target.value)}/>
            </div>
            <button disabled={submitDisabled()} onClick={onSubmit}>add</button>
          </div>
        )
      }
    </>
  )
}

const Place = ({ placeRef, onClick, name, address, distance, foodType }: PlaceProps) : JSX.Element | null => {
  const { status, data: place } = useFirestoreDocData( placeRef )

  return (!place || status == 'loading' ? null : (
    <div className={ style.root } onClick={()=>onClick && onClick(placeRef.id)} style={{ cursor: onClick ? 'pointer' : 'default' }} >
      {
        place.image && <img alt='place-cover' src={place.image} style={{ width: 120, height: 120 }}/>
      }
      <div className={ style.over }>
        { name && <span>{place.name}</span>}
        { address && <span>{place.address}</span>}
        { distance && <span>{place.distance}</span>}
        { foodType && <span>{place.foodtypes}</span>}
      </div>
    </div>
  ))
}


export { CreatePlace }
export default Place
