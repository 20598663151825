import React, {useState} from 'react'
import { useFirestore, useFirestoreDocData } from 'reactfire'
import { DocumentReference, DocumentData, doc, collection, addDoc, arrayRemove, arrayUnion, updateDoc,writeBatch } from 'firebase/firestore'
import ColorHash from 'color-hash'
import User from './User'
import useUser from './useUser'

import style from './Groups.module.scss'
import appstyle from '../App.module.scss'
interface GroupProps {
  groupRef: DocumentReference;
}

interface JoinGroupProps {
  group: DocumentData;
}

interface LeaveGroupProps {
  group: DocumentData;
}

const ch = new ColorHash({ saturation: 0.7, lightness: 0.5})

const CreateGroup = () : JSX.Element | null => {
  const db = useFirestore()
  const [user, userRef] = useUser()
  const [open, setOpen] = useState(false)
  const [groupName, setGroupName] = useState('')

  

  const openDialog =() => setOpen(true)
  const onClose =() => setOpen(false)

  const newGroupDisabled = ()=> groupName === ''

  const createNewGroup = () => {
    addDoc(collection(db, 'groups'), {
      color: ch.hex(groupName),
      name: groupName, 
      users: [userRef],
    })
    onClose()
  }

  return (
    <>
      { open &&
        <div style={{ border: '1px solid black', margin: 10 }}>
          <p>create group:</p>
          <input placeholder="group name" value={ groupName } onChange={(event)=>setGroupName(event.target.value)}/>
          <button disabled={newGroupDisabled()} onClick={createNewGroup} >create grp</button>
        </div>
      }
      { !open && <button disabled={open} onClick={openDialog} >create grp</button>}
    </>
  )
}

const JoinGroup = ({ group }: JoinGroupProps) : JSX.Element | null => {
  const db = useFirestore()
  const [_, userRef] = useUser()
  const includesUser = userRef && Boolean( group.users.find( ({ id }: DocumentData) => id === userRef.id ))

  const joinGroup = () => {
    const uRef = doc(db, 'users', userRef?.id)
    updateDoc( doc(db, 'groups', group?.id), { users: arrayUnion(uRef)})
  }

  return includesUser ? null : <button className={ `${appstyle.simpleButton} ${appstyle.white}`} onClick={joinGroup}>join</button>
}

const LeaveGroup = ({ group }: LeaveGroupProps) : JSX.Element | null => {
  const db = useFirestore()
  const [_, userRef] = useUser()
  const includesUser = userRef && Boolean( group.users.find( ({ id }: DocumentData) => id === userRef.id ))

  const leaveGroup = () => {
    const uRef = doc(db, 'users', userRef?.id)
    updateDoc( doc(db, 'groups', group?.id), { users: arrayRemove(uRef)})
  }

  return !includesUser ? null : <button className={ `${appstyle.simpleButton} ${appstyle.white}`} style={{ color: 'white'}} onClick={leaveGroup}>leave</button>
}

const Group = ({ groupRef }: GroupProps) : JSX.Element | null => {
  const { status, data } = useFirestoreDocData( groupRef, {idField: 'id'} )

  return (!data || status == 'loading' ? null : (
    <div className={ style.singleGroup } style={{ background: data.color || 'transparent'}}>
      <span >{ data.name }</span>
      <span style={{ fontSize: 26 }}>{ data.users.length }</span>
      <span>members</span>
      {/*
      <span>members:</span><br/>
      <ul>
        {
          data.users.map( (user:DocumentReference) => <li key={user.id} ><User userRef={ user }/></li>)
        }
      </ul>
      */}
      <LeaveGroup group={data} />
      <JoinGroup group={data} />
    </div>
  ))
}

export { CreateGroup }
export default Group
