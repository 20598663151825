// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_simpleButton__3jvIy {\n  padding: 10px 22px;\n  background: none;\n  border-radius: 9px;\n  border: none;\n  color: black;\n  text-transform: capitalize;\n  font-family: \"Nunito\";\n  font-weight: 700;\n  font-size: 16px;\n  z-index: 2;\n}\n\n.App_simpleButtonWhite__dAeuP {\n  color: white;\n}", "",{"version":3,"sources":["webpack://src/App.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,0BAAA;EAEA,qBAAA;EACA,gBAAA;EACA,eAAA;EACA,UAAA;AAAF;;AAGA;EACE,YAAA;AAAF","sourcesContent":[".simpleButton {\r\n  padding:  10px 22px;\r\n  background: none;\r\n  border-radius: 9px;\r\n  border: none;\r\n  color: black;\r\n  text-transform: capitalize;\r\n\r\n  font-family: 'Nunito';\r\n  font-weight: 700;\r\n  font-size: 16px;\r\n  z-index: 2;\r\n}\r\n\r\n.simpleButtonWhite {\r\n  color: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"simpleButton": "App_simpleButton__3jvIy",
	"simpleButtonWhite": "App_simpleButtonWhite__dAeuP"
};
export default ___CSS_LOADER_EXPORT___;
