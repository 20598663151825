import React, { useRef, ReactChildren, ReactChild } from 'react'
import style from './MainLayout.module.scss'
import { useSigninCheck } from 'reactfire'
import cx from 'classnames'
import { CSSTransition } from 'react-transition-group'
import Logo from '../image/logo.jpg'
import GroupMeal from '../image/group-meal.png'

interface MainLayoutProps {
  topContent?:  ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  children:  ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

const MainLayout: React.FC<MainLayoutProps> = ({ topContent, children }: MainLayoutProps) => {
  const { data: signInCheckResult } = useSigninCheck()
  const sliderRef = useRef(null)

  return (
    <div className={ style.root }>

      <CSSTransition
        nodeRef={sliderRef}
        in={signInCheckResult && !signInCheckResult.signedIn}
        classNames={{ ...style }}
        timeout={500}
      >
        <div ref={sliderRef} className={ style.slider }>

          <div className={ cx(style.sliderContent, style.sliderContentShifted) }>
            {topContent}
          </div>

          <div className={ style.sliderContent }>
            <div className={ style.content }>
              {children}
            </div>
          </div>

        </div>
      </CSSTransition>

    </div>
  )
}

export default MainLayout

