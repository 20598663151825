import React, {  ReactChildren, ReactChild } from 'react'
import { useSigninCheck, useAuth } from 'reactfire'
import { GoogleAuthProvider, signInWithPopup, signOut  } from 'firebase/auth'

import style from '../App.module.scss'
import authStyle from './AuthGate.module.scss'

const provider = new GoogleAuthProvider()

interface AuthGateProps {
  children:  ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

const SignIn = (): JSX.Element | null  => {
  const auth = useAuth()
  const { data: signInCheckResult } = useSigninCheck()
  const canSignIn = (signInCheckResult && !signInCheckResult.signedIn) || null

  const doSignIn = () => {
    signInWithPopup(auth, provider)
      .then( result => console.log('login result', result))
      .catch(error => console.log('login error', error))
  }

  return canSignIn && <button className={ style.simpleButton } onClick={ doSignIn }>Sign In</button>
}

const SignOut = (): JSX.Element | null  => {
  const auth = useAuth()
  const { data: signInCheckResult } = useSigninCheck()
  const isSignedIn = signInCheckResult?.signedIn || null

  const doSignOut = () => {
    signOut(auth)
      .then((result) => console.log('logout result', result))
      .catch((error) => console.log('logout error', error))
  }

  return isSignedIn && <button className={ style.simpleButton } onClick={ doSignOut }>sing out</button>
}


const AuthGate: React.FC<AuthGateProps> = ({ children }: AuthGateProps) => {
  const { data: signInCheckResult } = useSigninCheck()
  const isSignedIn = Boolean(signInCheckResult?.signedIn) || null

  return (<>
    { !isSignedIn && <div className={ authStyle.root }></div>}
    { isSignedIn && children}
  </>)
}

export { SignIn, SignOut }
export default AuthGate
