import React from 'react'
import Event from './Event'
import { collection, query, orderBy, doc, DocumentData } from 'firebase/firestore'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'
import useUser from './useUser'
import { CreateEvent } from './Event'

import style from './Groups.module.scss'

const UpcomingEvents = () : JSX.Element | null => {
  const db = useFirestore()
  const [_, userRef] = useUser()
  const eventsQuery = query( collection( useFirestore(), 'events'), orderBy('startsAt', 'desc'))

  const { status, data: events } = useFirestoreCollectionData(eventsQuery, {idField: 'id'})

  return (
    !events || status == 'loading' ? null : (
      <div className={ `${style.root} ${style.secondary}` }>
        <p className={ style.title }>Browse events</p>
        <div className={ style.flexContainer }>
          {
            events
              .filter( event => !event.users.find( ({ id }: DocumentData) => id === userRef?.id ))
              .map( ( event: DocumentData ) =>
                <Event key={event.id} eventRef={ doc( db,'events', event.id) }/>
              )
          }
        </div>
        <CreateEvent />
      </div>
    )
  )
}

export default UpcomingEvents
