import React from 'react'
import { collection, query, DocumentData, doc, where } from 'firebase/firestore'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'
import Group, { CreateGroup } from './Group'
import useUser from './useUser'

import style from './Groups.module.scss'

const Groups = () : JSX.Element | null => {
  const db = useFirestore()
  const [_, uRef] = useUser()
  const groupsQuery = query( collection( useFirestore(), 'groups'), where('users','array-contains',uRef) )

  const { status, data: groups } = useFirestoreCollectionData(groupsQuery, {idField: 'id'})

  return (
    !groups || status == 'loading'  ? null : (
      <div className={`${style.root}`}>
        <p className={ style.title }>Your groups</p>
        <div className={style.flexContainer}>
          {
            groups
              .map( ( group: DocumentData ) => {
                return <Group key={group.id} groupRef={ doc(db, 'groups', group?.id) } />}
              )
          }
          <CreateGroup />
        </div>
      </div>
    )
  )
}

export default Groups
