import React from 'react'
import { collection, query, doc } from 'firebase/firestore'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'
import Place, { CreatePlace } from './Place'
import Places from './Places'

interface PlaceSelectProps {
  value: string;
  onChange: any;
}

const SelectPlace = ({ value, onChange } : PlaceSelectProps ) : JSX.Element | null => {
  const db = useFirestore()
  const eventsQuery = query( collection( useFirestore(), 'places') )

  const { status, data: places } = useFirestoreCollectionData(eventsQuery, {idField: 'id'})

  return (
    !places || status == 'loading' ? null : (
      <div>
        { value && places.length > 0 && (
          <div style={{ border: '1px solid red' }}>
            <Place placeRef={ doc(db, 'places', value) } onClick={onChange} />
          </div>
        )
        }
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <Places onClick={onChange}/>
        </div>
      </div>
    )
  )
}

export default SelectPlace
