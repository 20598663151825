import React from 'react'
import { collection, query, DocumentData, DocumentReference, doc } from 'firebase/firestore'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'
import Group from './Group'
import useUser from './useUser'

import style from './Groups.module.scss'

const Groups = () : JSX.Element | null => {
  const db = useFirestore()
  const groupsQuery = query( collection( useFirestore(), 'groups') )
  const [_, uRef] = useUser()

  const { status, data: groups } = useFirestoreCollectionData(groupsQuery, {idField: 'id'})

  return (
    !groups || status == 'loading' ? null : (
      <div className={`${style.root} ${style.secondary}`}>
        <p className={ style.title }>Browse groups</p>
        <div className={style.flexContainer}>
          {
            groups
              .filter( ({ users }: DocumentData) => !users.find( ({ id }:DocumentReference) => id === uRef?.id ) )
              .map( ( group: DocumentData ) => {
                return <Group key={group.id} groupRef={ doc(db, 'groups', group?.id) } />}
              )
          }
        </div>
      </div>
    )
  )
}

export default Groups
