// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Place_root__2c0L9 {\n  display: flex;\n  position: relative;\n  width: 120px;\n  height: 120px;\n  border-radius: 50%;\n}\n.Place_root__2c0L9:after {\n  content: \"\";\n  width: 120px;\n  height: 120px;\n  top: 0;\n  display: block;\n  background-color: rgba(50, 25, 52, 0.7);\n  left: 0;\n  position: absolute;\n}\n.Place_root__2c0L9 > img {\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.Place_over__2Ch-N {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  z-index: 5;\n}", "",{"version":3,"sources":["webpack://src/component/Place.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,MAAA;EACA,cAAA;EACA,uCAAA;EACA,OAAA;EACA,kBAAA;AACJ;AAEE;EACE,kBAAA;EAEA,MAAA;EACA,OAAA;AADJ;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,UAAA;AAFF","sourcesContent":[".root {\r\n  display: flex;\r\n  position: relative;\r\n  width: 120px;\r\n  height: 120px;\r\n  border-radius: 50%;\r\n\r\n  &:after {\r\n    content: \"\";\r\n    width: 120px;\r\n    height: 120px;\r\n    top: 0;\r\n    display: block;\r\n    background-color: rgba(50, 25, 52, 0.7);\r\n    left: 0;\r\n    position: absolute;\r\n  }\r\n\r\n  & > img {\r\n    position: absolute;\r\n\r\n    top: 0;\r\n    left: 0;\r\n  }\r\n}\r\n\r\n.over {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex-grow: 1;\r\n  z-index: 5;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Place_root__2c0L9",
	"over": "Place_over__2Ch-N"
};
export default ___CSS_LOADER_EXPORT___;
