// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthGate_root__1VQoS {\n  position: absolute;\n  top: 0;\n  left: 0;\n  color: white;\n}", "",{"version":3,"sources":["webpack://src/component/AuthGate.module.scss"],"names":[],"mappings":"AAAA;EAGE,kBAAA;EAEA,MAAA;EACA,OAAA;EAEA,YAAA;AAHF","sourcesContent":[".root {\r\n  //width: 100vw;\r\n  //height: 100vh;\r\n  position: absolute;\r\n  //z-index: 999;\r\n  top: 0;\r\n  left: 0;\r\n  //background-color: #D9135D;\r\n  color: white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "AuthGate_root__1VQoS"
};
export default ___CSS_LOADER_EXPORT___;
