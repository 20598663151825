import React, { useState } from 'react'
import useUser from './useUser'
import Event from './Event'
import Chat from './Chat'

import style from './Groups.module.scss'
import appstyle from '../App.module.scss'

const CurrentEvent = () : JSX.Element | null => {
  const [user] = useUser()
  const [ showDetails, setShowDetails ] = useState(false)

  return (!user || status == 'loading' ? null : (
    <>
      <div className={ `${style.root} ${showDetails ? style.secondary : ''}` } onClick={()=>setShowDetails(true)}>
        {showDetails && <button className={ appstyle.simpleButton} onClick={ (event)=>{ event.stopPropagation(); setShowDetails(false) } } >X</button>}
        <p>Your upcoming event</p>
        {
          user.currentEvent
            ? <Event eventRef={user.currentEvent}/>
            : <p>
              You don’t have any upcoming events yet. Browse the circles below or create a new one.
            </p>
        }
      </div>
      { user.currentEvent && showDetails && <Chat eventRef={user.currentEvent} /> }
    </>
  ))
}

export default CurrentEvent
