import React, {useState} from 'react'
import { useFirestore, useFirestoreDocData } from 'reactfire'
import { DocumentReference, DocumentData, doc, collection, addDoc, arrayRemove, arrayUnion, serverTimestamp, writeBatch } from 'firebase/firestore'
import Place from './Place'
import SelectPlace from './SelectPlace'
import User from './User'
import useUser from './useUser'

import { uniqueNamesGenerator, Config, adjectives, animals } from 'unique-names-generator'

import style from './Event.module.scss'
import appstyle from '../App.module.scss'

const customConfig: Config = {
  dictionaries: [adjectives, animals],
  separator: ' ',
  length: 2,
}


interface EventProps {
  eventRef: DocumentReference;
}

interface JoinEventProps {
  event: DocumentData;
}

const CreateEvent = () : JSX.Element | null => {
  const db = useFirestore()
  const [user, userRef] = useUser()
  const [open, setOpen] = useState(false)
  const [selectedPlace, setSelectedPlace] = useState('')
  const [selectedStart, setSelectedStart] = useState( (new Date()).toString() )
  const [selectedType, setSelectedType] = useState('dine-in')

  const openDialog =() => {
    setSelectedPlace('')
    setSelectedStart('')
    setOpen(true)
  }
  const onClose =() => setOpen(false)

  const newEventDisabled = ()=>[selectedPlace, selectedStart, selectedType].includes('')

  const createNewEvent = async () => {
    const newEventRef = await addDoc(collection(db, 'events'), {
      name: uniqueNamesGenerator(customConfig) + ' Event',
      place: doc( db, 'places', selectedPlace),
      startsAt: new Date(selectedStart),
      type: selectedType,
      users: [],
    })
    addDoc( collection(newEventRef, 'chat'), { authorName: 'System', authorRef: null, createdAt: serverTimestamp(), message: 'Event created.' })

    joinEvent( newEventRef )
    onClose()
  }

  const joinEvent = ( newEventRef:DocumentReference ) => {
    const batch = writeBatch(db)
    const oldEventRef = user?.currentEvent
    const uRef = doc(db, 'users', userRef?.id)

    if ( oldEventRef ){
      batch.update( oldEventRef, { users: arrayRemove(uRef) })
    }

    batch.update( newEventRef, { users: arrayUnion( uRef ) })
    batch.update( uRef, { currentEvent: newEventRef })

    batch.commit()
  }

  return (
    <>
      { open &&
        <div style={{ zIndex: 0}}>
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100vw', minHeight: '100vh', background: '#F2F2F2', padding: '30px', zIndex: 10 }}>
            <button className={ appstyle.simpleButton} onClick={ (event)=>{ event.stopPropagation(); setOpen(false) } } >X</button>
            <br/>
            <h3 className={ style.title }>Create new event</h3>
            <input placeholder="starts at" type='datetime-local' value={ selectedStart } onChange={(event)=>setSelectedStart(event.target.value)}/>
            <SelectPlace value={selectedPlace} onChange={setSelectedPlace}/>
            <select value={selectedType} onChange={(event)=>setSelectedType(event.target.value)} >
              <option value='dine-in'>Dine-in</option> 
              <option value='takeaway'>Takeaway</option> 
              <option value='delivery'>Delivery</option> 
            </select>
            <br/>
            <button disabled={newEventDisabled()} onClick={createNewEvent} >create event</button>
          </div>
        </div>
      }
      { !open && <button disabled={open} onClick={openDialog} >create event</button>}
    </>
  )
}

const JoinEvent = ({ event }: JoinEventProps) : JSX.Element | null => {
  const db = useFirestore()
  const [user, userRef] = useUser()
  const includesUser = userRef && Boolean( event.users.find( ({ id }: DocumentData) => id === userRef.id ))

  const joinEvent = () => {
    const batch = writeBatch(db)
    const oldEventRef = user?.currentEvent
    const newEventRef = doc(db, 'events', event?.id)
    const uRef = doc(db, 'users', userRef?.id)

    if ( oldEventRef ){
      batch.update( oldEventRef, { users: arrayRemove(uRef) })
    }

    batch.update( newEventRef, { users: arrayUnion( uRef) })
    batch.update( uRef, { currentEvent: newEventRef })

    batch.commit()
  }

  return includesUser ? null : <button className={ appstyle.simpleButton } style={{ color: 'white'}} onClick={joinEvent}>join</button>
}

const Event = ({ eventRef }: EventProps) : JSX.Element | null => {
  const { status, data } = useFirestoreDocData( eventRef, {idField: 'id'} )

  return (!data || status == 'loading' ? null : (
    <div className={style.root}>
      { data.place && <Place placeRef={data.place} name /> }
      {/*<p>{ data.name }</p>*/}
      <JoinEvent event={data} />
      <span>{data.startsAt && data.startsAt.toDate().toLocaleString()}</span>
      {/*
            <span>users attending:</span>
      <ul>
        {
          data.users.map( (user:DocumentReference) => <li key={user.id} ><User userRef={user} showGroupMarkers /></li> )
        }
      </ul>
      */}
    </div>
  ))
}

export { CreateEvent }
export default Event
