import React, { useState } from 'react'
import { collection, query, DocumentData, serverTimestamp, orderBy, DocumentReference, addDoc} from 'firebase/firestore'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'
import Group, { CreateGroup } from './Group'
import useUser from './useUser'

import style from './Groups.module.scss'

interface ChatProps {
  eventRef: DocumentReference;
}

const Chat = ({ eventRef }: ChatProps) : JSX.Element | null => {
  const [ newMessage, setNewMessage ] = useState('')
  const messagesRef = collection( eventRef, 'chat')
  const messagesQuery = query( messagesRef, orderBy('createdAt', 'desc') )
  const [user, uRef] = useUser()

  const { status, data: messages } = useFirestoreCollectionData(messagesQuery, {idField: 'id'})

  const addNewMessage = async () => {
    await addDoc( messagesRef, {
      authorName: user?.displayName,
      authorRef: uRef,
      createdAt: serverTimestamp(),
      message: newMessage
    })
    setNewMessage('')
  }

  return (
    !messages || status == 'loading' ? null : (
      <div className={ `${style.root} ${style.chatRoot} ` }>
        {
          messages.map( ( message:any ) =>
            <>
              <span key={message.id} >
                {message.authorName + ': ' + message.message}
              </span>
              <br/>
            </>
          )
        }
        <br/>
        <input value={newMessage} onChange={(event)=>setNewMessage(event.target.value)} />
        <button onClick={addNewMessage} disabled={ !newMessage.length }>send</button>
      </div >
    )
  )
}

export default Chat
