import { useFirestore, useFirestoreDocData, useUser as useAuthUser } from 'reactfire'
import { doc, collection } from 'firebase/firestore'

const useUser = ( id?: string ) => {
  const db = useFirestore()
  const { data: authUser } = useAuthUser()
  const userRef = doc( collection(db, 'users'), id || authUser?.uid || '__NONEXISTENT__' )
  const { status, data: user } = useFirestoreDocData(userRef)

  return [status === 'loading' ? null : user, userRef]
}

export default useUser