// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Event_root__1y0mA {\n  width: 120px;\n  height: 120px;\n  border-radius: 50%;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  margin: 12px;\n  color: white !important;\n}\n\n.Event_secondary__1jcDZ {\n  background-color: #F2F2F2;\n}", "",{"version":3,"sources":["webpack://src/component/Event.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EAEA,uBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF","sourcesContent":[".root {\r\n  width: 120px;\r\n  height: 120px;\r\n  border-radius: 50%;\r\n  overflow: hidden;\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin: 12px;\r\n\r\n  color: white !important;\r\n}\r\n\r\n.secondary {\r\n  background-color: #F2F2F2;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Event_root__1y0mA",
	"secondary": "Event_secondary__1jcDZ"
};
export default ___CSS_LOADER_EXPORT___;
