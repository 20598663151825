import React from 'react'
import { collection, query, DocumentData, doc} from 'firebase/firestore'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'
import Place, { CreatePlace } from './Place'

interface PlaceSelectProps {
  onClick?: any;
}

const Places = ({onClick}:PlaceSelectProps) : JSX.Element | null => {
  const db = useFirestore()
  const eventsQuery = query( collection( useFirestore(), 'places') )

  const { status, data: places } = useFirestoreCollectionData(eventsQuery, {idField: 'id'})

  return (
    !places || status == 'loading' ? null : (
      <div>
        {
          places
            .map( ( place: DocumentData ) =>
              <Place key={place.id} onClick={onClick} placeRef={ doc(db, 'places', place?.id) } />
            )
        }
        <br/>
        <CreatePlace />
      </div>
    )
  )
}

export default Places
